import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';
/**
 * 次卡管理 - 场地列表
 * @param params
 */
export function getScheduleList(params){
    return request({
        url: envInfo.bgApp.customerGSPath + '/site/card/queryList',
        method: 'get',
        params,
    });
}
/**
 * 次卡管理 - 启用禁用
 * @param params
 */
export function editCardStatus(params){
    return request({
        url: envInfo.bgApp.customerGSPath + '/site/card/editDisable',
        method: 'get',
        params,
    });
}
/**
 * 次卡管理 - 编辑
 * @param params
 */
export function editCardList(params){
    return request({
        url: envInfo.bgApp.customerGSPath + '/site/card/edit',
        method: 'post',
        data:params,
    });
}
/**
 * 次卡管理 - 获取详情
 * @param params
 */
export function getDetailList(params){
    return request({
        url: envInfo.bgApp.customerGSPath + '/site/card/getDetails',
        method: 'get',
        params,
    });
}
/**
 * 次卡管理  - 获取历史特殊日期
 * @param params
 */
export function getHistoryList(params){
    return request({
        url: envInfo.bgApp.customerGSPath + '/site/card/queryHistorySpecialDate',
        method: 'get',
        params,
    });
}