<template>
    <div class="app-container stadium-config">
        <div class="filter-line">
            <span>
                <el-button icon="el-icon-refresh-right" @click="getTableList">{{ this.$t("commons.refresh") }}</el-button>
            </span>
            <div class="header-filter-button" @click="dataset.filterLine.show = !dataset.filterLine.show"
                :class="dataset.filterLine.show ? 'filter-button-open' : 'filter-button-close'">
                <i class="iconfont icon-loudoutu"></i>筛选
            </div>
        </div>
        <div>
            <finalTable ref="finalTableRef" :datas="dataset" @tableEventHandler="tableEventHandler"></finalTable>
        </div>
        <!-- 编辑弹窗 -->
        <el-dialog title="编辑" :visible.sync="dialogVisible" width="728px" :before-close="handleClose"
            :close-on-click-modal="false">
            <span>
                <detailed :detailsObj="detailsObj" ref="detailedRef"></detailed>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleClose">取 消</el-button>
                <el-button type="primary" @click="editDetailed">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import finalTable from "@/components/FinalTable";
import {
    getScheduleList,
    editCardStatus,
    editCardList,
    getDetailList
} from "@/api/ruge/gsPark/customerService/config";
import detailed from "./detailed"
export default {
    name: "configComponent",
    components: {
        finalTable,
        detailed
    },

    data() {
        return {
            detailsObj: {},
            dialogVisible: false,
            dataset: {
                paginationConfig: {
                    need: true,
                },
                filterLine: {
                    show: true,
                },
                header: [
                    { prop: "cardName", label: "次卡类型", width: "" },
                    { prop: "cardStatus", label: "是否开放", width: "" },
                    { prop: "operation", label: "操作", width: "110" },
                ],
                tableData: [],
                // 搜索行配置
                searchLineConfig: {
                    cardName: {
                        type: "input",
                        label: "次卡类型",
                        value: "",
                        actionType: "goSearch",
                        placeholder: "请输入次卡类型",
                        prefixIcon: "el-icon-search",
                    },
                    cardStatus: {
                        type: "selector",
                        label: "是否开发",
                        value: "",
                        actionType: "goSearch",
                        // optionList: [],
                        optionList: [
                            {
                                label: "是",
                                value: "ON",
                            },
                            {
                                label: "否",
                                value: "OFF",
                            },
                        ],
                    },
                    // operation: {
                    //   type: "button",
                    //   filterCount: "",
                    //   actionType: "clickEvent",
                    //   eventName: "showMoreFilters",
                    //   label: "更多筛选",
                    // },
                },
                // 表格内容配置
                detailConfig: {
                    operation: {
                        type: "icon",
                        iconList: [
                            {
                                actionType: "editClick",
                                eventName: "view",
                                fontSize: "14px",
                                color: "#1A4CEC",
                                cursorPointer: "pointer",
                                iconName: "el-icon-view",
                                tooltips: "编辑",
                            }
                        ],
                    },
                    cardStatus: {
                        type: "switch",
                        actionType: "switchEvent",
                    }
                },
                // 高级搜索配置
                advanceFilterConfig: {},
                pageVO: {
                    current: 1,
                    rowCount: 10,
                    total: 0,
                },
            },
            cardObj: {
                tableLoading: false,
                listQuery: {
                    current: 1,
                    rowCount: 10,
                    cardStatus: null,
                    cardType: null
                }
            }
        }
    },
    created() {
        this.getTableList()
    },
    methods: {
        // 初始化数据
        getTableList() {
            this.cardObj.tableLoading = true;
            const params = { ...this.cardObj.listQuery };
            for (let pn in params) {
                !params[pn] && params[pn] !== 0 && (params[pn] = null);
            }
            getScheduleList(params)
                .then((res) => {
                    res.rows.forEach((item) => {
                        item.cardStatus = item.cardStatus === "ON" ? "ENABLE" : "DISABLE";
                    });
                    console.log(res, '初始化数据');
                    this.dataset.pageVO.total = res.total;
                    this.dataset.tableData = res.rows;
                })
                .finally(() => {
                    this.cardObj.tableLoading = false;
                })
        },
        // table组件页面事件配置
        tableEventHandler(datas) {
            // console.log(datas, 'datasdatasdatas');
            if (datas.type === "goSearch") {
                console.log(datas.params, 'datas.paramsdatas.params');
                this.cardObj.listQuery = {
                    ...this.cardObj.listQuery,
                    ...datas.params,
                };
                this.cardObj.listQuery.current = 1;

                this.getTableList()
            } else if (datas.type === "switchEvent") {
                this.updateEnableStatus(datas.row);
                // console.log(datas.row, 'row');
            } else if (datas.type === "editClick") {
                console.log(datas.row.id, 'datas.row.id');
                this.getTableList()
                this.dialogVisible = true
                getDetailList({
                    id: datas.row.id
                }).then((res) => {
                    console.log(res, 'res');
                    this.detailsObj = res
                })
            } else if (datas.type === "paginationChange") {
                // 配置分页
                this.cardObj.listQuery.current = datas.params.current.page;
                this.cardObj.listQuery.rowCount = datas.params.current.limit;
                this.getTableList();
            }
        },
        // 是否已开放 按钮 状态配置
        updateEnableStatus({ id, cardStatus }) {
            const emuMap = {
                ENABLE: "ON",
                DISABLE: "OFF",
            };
            cardStatus;
            editCardStatus({
                id,
                cardStatus: emuMap[cardStatus],
            }).then((res) => {
                if (res) {
                    this.$message.success("修改状态成功！");
                    this.getTableList();
                }
            });
        },
        // 弹窗关闭配置
        handleClose(done) {
            this.dialogVisible = false;
            let newWeeks = [
                    {
                        openTimeType: 'normal',
                        openTime: '',
                        openWeek: '周一',
                        status: false,
                        money: ''

                    },
                    {
                        openTimeType: 'normal',
                        openTime: '',
                        openWeek: '周二',
                        status: false,
                        money: ''

                    },
                    {
                        openTimeType: 'normal',
                        openTime: '',
                        openWeek: '周三',
                        status: false,
                        money: ''

                    },
                    {
                        openTimeType: 'normal',
                        openTime: '',
                        openWeek: '周四',
                        status: false,
                        money: ''

                    },
                    {
                        openTimeType: 'normal',
                        openTime: '',
                        openWeek: '周五',
                        status: false,
                        money: ''

                    },
                    {
                        openTimeType: 'normal',
                        openTime: '',
                        openWeek: '周六',
                        status: false,
                        money: ''

                    },
                    {
                        openTimeType: 'normal',
                        openTime: '',
                        openWeek: '周日',
                        status: false,
                        money: ''

                    },
                ]
            if (this.$refs["detailedRef"]) {
                this.$refs["detailedRef"].flag = false
                this.$refs["detailedRef"].current = 1
                this.$refs["detailedRef"].weeks = newWeeks
                this.$refs["detailedRef"].specialWeeks = []
                this.$refs["detailedRef"].conventionTime = "-1"
                this.$refs["detailedRef"].specialTime = "-1"
            }
        },
        // 编辑
        editDetailed() {
            this.$refs["detailedRef"].$refs.ruleForm.validate((valid) => {
                let arrList = this.$refs["detailedRef"].weeks
                let arrList1 = this.$refs["detailedRef"].specialWeeks
                const result = [...arrList, ...arrList1].map(item => ({ ...item }));
                console.log(result,'查看钱');
                const result1 = result.map(({ openTimeType, openWeek, openTime, status, money }) => ({
                    openTimeType,
                    openWeek,
                    openTime,
                    status: status === true ? "ON" : "OFF",
                    money
                }));
                let newWeeks = [
                    {
                        openTimeType: 'normal',
                        openTime: '',
                        openWeek: '周一',
                        status: false,
                        money: ''

                    },
                    {
                        openTimeType: 'normal',
                        openTime: '',
                        openWeek: '周二',
                        status: false,
                        money: ''

                    },
                    {
                        openTimeType: 'normal',
                        openTime: '',
                        openWeek: '周三',
                        status: false,
                        money: ''

                    },
                    {
                        openTimeType: 'normal',
                        openTime: '',
                        openWeek: '周四',
                        status: false,
                        money: ''

                    },
                    {
                        openTimeType: 'normal',
                        openTime: '',
                        openWeek: '周五',
                        status: false,
                        money: ''

                    },
                    {
                        openTimeType: 'normal',
                        openTime: '',
                        openWeek: '周六',
                        status: false,
                        money: ''

                    },
                    {
                        openTimeType: 'normal',
                        openTime: '',
                        openWeek: '周日',
                        status: false,
                        money: ''

                    },
                ]
                if (valid) {
                    let ruleFormList = {
                        id: this.detailsObj.id,
                        cardName: this.$refs["detailedRef"].detailsObj.cardName,
                        cardType: this.$refs["detailedRef"].detailsObj.cardType,
                        cardStatus: this.$refs["detailedRef"].value === true ? "ON" : "OFF",
                        siteCardManageTimeList: result1
                    }

                    editCardList(ruleFormList).then((res) => {
                        this.dialogVisible = false;
                        this.$refs["detailedRef"].current = 1
                        this.$refs["detailedRef"].weeks = newWeeks
                        this.$refs["detailedRef"].specialWeeks = []
                        this.$refs["detailedRef"].conventionTime = "-1"
                        this.$refs["detailedRef"].specialTime = "-1"
                        this.getTableList()
                    })
                    console.log(ruleFormList, 'ruleFormList');
                }
            })
            this.$refs["detailedRef"].flag = false
        }
    },
    watch: {
        cardObj: {
            handler(val) {
                this.$refs.finalTableRef.loadingToggle(val.tableLoading);
            },
            deep: true,
        },
    }
}
</script>
<style lang="less" scoped>
.stadium-config {
    .filter-line {
        margin-bottom: 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        button {
            height: 36px;
        }

        .icon-loudoutu {
            font-size: 12px;
        }

        .header-filter-button {
            cursor: pointer;
            width: 84px;
            height: 36px;
            line-height: 36px;
            text-align: center;
            border-radius: 3px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
        }

        .filter-button-open {
            color: #409eff;
            background: #ecf5ff;
            border: 1px solid #c6e2ff;
        }

        .filter-button-close {
            color: #2a4158;
            background: #ffffff;
            border: 1px solid #e7e8eb;
        }
    }

    :deep(.el-dialog) {
        height: 100%;

        .el-dialog__body {
            max-height: calc(100% - 154px);
            overflow-y: auto;
        }
    }
}
</style>